import * as actionTypes from "../../store/actions/actionTypes";
import api from "../../userAPI";
import basicApi from '../../api'
import thirdPartyApi from "./Api";
import * as APIConstants from "../ClubManager/Apiconstants";
import * as wexerApiConstants from '../../store/APIConstants';
import { Translate } from "../../utils/Translate";
import { GetUserDetail } from "../../store/actions/auth";
import { qsStrigify } from "../../../src/utils/utilityfunctions";

const config = require('../../assets/config.json')
//import { logoutUnsubscribedUsers, logout } from '../../containers/screens/login/action'


var Environment = require('./config');
const produceUserDto = () => {
  return {
    firstName: "",
    lastName: "",
    response_type: "token",
    token: "",
    countryCode: "",
    consentRequest: [],
    email: "",
    tenantId: "",
    username: "",
    redirect_uri: "http://localhost:64116/",
    clientID: "",
    scope: "openid"
  };
};


export const AuthenticateWexerUser = (
  uname,
  exerpToken,
  email,
  exerpProfile
) => {
  return dispatch => {
    dispatch({ type: actionTypes.AUTH_START });


    var bodyFormData = new FormData();
    bodyFormData.append("client_id", process.env.REACT_APP_API_CLIENT_ID);
    bodyFormData.append("redirect_uri", process.env.REACT_APP_API_BASE_URL);
    bodyFormData.append("response_type", "token");
    bodyFormData.append("scope", "openid");
    bodyFormData.append("username", email);
    bodyFormData.append("tenantId", localStorage.getItem('clientId'));
    bodyFormData.append("token", exerpToken);
    bodyFormData.append("countrycode", "uk");
    bodyFormData.append("email", email);
    bodyFormData.append("skipConsent", "true");
    bodyFormData.append(
      "consentrequest",
      JSON.stringify([
        {
          ConsentTag: "f84c1942-5dda-4ef9-9417-1b6ec402d344",
          Action: 1
        }
      ])
    );
    console.log(bodyFormData)
    basicApi.setHeader("Content-Type", "multipart/form-data");

    basicApi
      .post(wexerApiConstants.thirdPartyUserOnWexerApi, bodyFormData)
      .then(response => {
        console.log(response)
        if (response.ok) {
          // if user has been created, show onboarding flow


          if (response.status == 201) {



            try {

              console.log(response.data.UserDetail.Code + "and" + response.data.UserDetail.UserId)
              localStorage.setItem('token', response.data.UserDetail.Code)
              localStorage.setItem('userId', response.data.UserDetail.UserId)
              dispatch({
                type: actionTypes.AUTH_SUCCESS,
                token: response.data.UserDetail.Code,
                userId: response.data.UserDetail.UserId,
                firstName: exerpProfile.first_name
              });

              dispatch(GetUserDetail())
              //dispatch(getCustomFitProfileForExistingUser());
              //dispatch(getWorkoutListOnAppLaunch(false));
            } catch (error) {
              console.log(error);
            }
          }


          // if it is existing user no need to display onboarding flow.
          else if (response.status == 200) {
            try {

              console.log(response.data)

              localStorage.setItem('token', response.data.Code)
              localStorage.setItem('userId', response.data.UserId)


              dispatch({
                type: actionTypes.AUTH_SUCCESS,
                token: response.data.Code,
                userId: response.data.UserId,
                firstName: exerpProfile.first_name
              });
              //dispatch({ type: actionTypes.ONBOARD_STATUS_CHANGED, status: true, data: null })
              dispatch(GetUserDetail())

            } catch (error) {
              console.log(error);
            }
          }
        } else {
          if (response.status === 404) {
            dispatch({
              type: actionTypes.AUTH_FAIL,
              // error: Translate({
              //   id: 'Subscription.UpgradeMessage'
              // })
            });
            dispatch({type:actionTypes.SHOW_POPUP_SSO})
          } else if (response.status === 500) {
            dispatch({
              type: actionTypes.AUTH_FAIL,
              error: Translate({ id: 'ApiResponseStrings.InternalServerError' })
            });
          }

          else {
            dispatch({
              type: actionTypes.AUTH_FAIL,
              error: response.originalError.message
            });
          }
        }
      });
  };
};




export const getThirdPartyUserSubscription = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.FETCH_USER_SUBSCRIPTION_START
    });  
    const UserAuthData = localStorage.getItem("thirdPartyAuthor");

    if (UserAuthData != null) {
      let userAPI = thirdPartyApi()
      userAPI.setHeader("Authorization", `Bearer ${UserAuthData}`)
      
      userAPI.get(APIConstants.getUserSubscription)
        .then(response => {
          console.log(" getThirdPartyUserSubscription => ", response);
          if (response.status == 200) {
            if (response.data) {
              if (response.data.hasBoltOn) {
                dispatch({
                  type: actionTypes.FETCH_USER_SUBSCRIPTION_SUCCESS,
                  userSubscripton: response.data,
                  status: true
                });
              }
              else {
                dispatch(logout());
                dispatch({
                  type: actionTypes.FETCH_USER_SUBSCRIPTION_SUCCESS,
                  status: false
                });
              }
            }
            else {
              dispatch(logout());
              dispatch({
                type: actionTypes.FETCH_USER_SUBSCRIPTION_SUCCESS,
                status: false
              });
            }
          } else {
            console.log(`userSusbcription error ${response}`);
            dispatch(logout())
            dispatch({
              type: actionTypes.FETCH_USER_SUBSCRIPTION_FAIL
            });
          }
        })
        .catch(function (er) {
          console.log(`userSusbcription catch ${er}`);
        });
    }
  };
};


export const logout = () => {
  return dispatch => {
    if (config.canUnsubcribedUsersLogin == false) {

      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      dispatch({
        type: actionTypes.AUTH_LOGOUT,
        status: false
      });

    }
  }
}
export const createThirdPartyUser = (data) => {
  return dispatch => {}
};
export const AuthenticateThirdPartyUser = ({email:uname, password:pwd}) => {
  return dispatch => {
    dispatch({ type: actionTypes.THIRD_PARTY_AUTH_START });
   
    const body=qsStrigify({
      username: uname,
      password:pwd,
      grant_type: "password"
    })
    
    thirdPartyApi()
      .post(APIConstants.LoginAPI,body)
      .then(

        response => {
          console.log(response)
          if (response.status == 200) {
            console.log(response);
              localStorage.setItem("thirdPartyAuthor", response.data.access_token);
              try {
                dispatch({
                  type: actionTypes.THIRD_PARTY_AUTH_SUCCESS,
                  token: response.data.access_token,
                });
                let userDto = produceUserDto();
                userDto.clientID = "1234123412341234";
                userDto.skipConsent = "true";
                userDto.token = response.data.access_token;
                userDto.username = uname;
                userDto.email = uname;
                userDto.firstName = "";
                userDto.lastName = "";
                userDto.countryCode = "";
                userDto.response_type = "token";
                userDto.tenantId =  localStorage.getItem('clientId')

                let userData = {
                  first_name: response.data.firstName,
                  last_name: response.data.lastName,
                  email: response.data.username,
                  id: ""
                }
                dispatch(
                  AuthenticateWexerUser(
                    userDto.username,
                    userDto.token,
                    userDto.email,
                    userData
                  )
                );

              } catch (error) {
                console.log(error);
              }
            }  

          else {
            if (response.status === 400 ) {
                dispatch({
                type: actionTypes.AUTH_FAIL,
               error: Translate({
                  id: 'ApiResponseStrings.InvalidUserNameOrPassword'
                })
              })
            } else if (response.status === 500) {
              dispatch({
                type: actionTypes.AUTH_FAIL,
                error: Translate({ id: 'ApiResponseStrings.InternalServerError' })
              });
            } else {
              console.log("authfailed");
              dispatch({
                type: actionTypes.AUTH_FAIL,
                error: response.originalError.message
              });
            }
          }
        },
        err => {
          console.log(err);
          dispatch({
            type: actionTypes.AUTH_FAIL,
            error: err.originalError
          });
        }
      )
  };
};

export const thirdPartyResetPassword = emailId => {
  return dispatch => {
    dispatch({ type: actionTypes.RESET_PASSWORD_START });
    var body = {
      username: emailId
    };

    // thirdPartyApi()
    //   .post(APIConstants.ResetPassword + emailId)
    //   .then(response => {
    //     console.log(response)
    //     if (response.ok) {
    //       if (response.status == 200) {
    //         if (response.data.length > 0 && response.data[0].status == "sent") {
    //           dispatch({ type: actionTypes.SEND_FORGOT_PASSWORD_MAIL_SUCCESS });
    //           return response.data;
    //         }
    //         else {
    //           dispatch({ type: actionTypes.SEND_FORGOT_PASSWORD_MAIL_FAIL, errorMsg: "No account could be found associated with this email address." });
    //           console.log(response);
    //         }

    //       } else {
    //         dispatch({ type: actionTypes.SEND_FORGOT_PASSWORD_MAIL_FAIL });
    //         console.log(response);
    //       }
    //     } else {
    //       dispatch({
    //         type: actionTypes.SEND_FORGOT_PASSWORD_MAIL_FAIL
    //       });
    //     }
    //   });

  };
};

