
var _Environments = {
    production: { 
            BASE_URL: "https://api.hedgehogconcept.net/wexer",
            RESET_PASSWORD: "https://ma.activenation.org.uk/Account/ForgotPassword",
            JWPLAYER:'95riWD5s.js'
        },
    test: { BASE_URL: "https://tapi.hedgehogconcept.net/wexer",
            RESET_PASSWORD: "https://ma.activenation.org.uk/Account/ForgotPassword",
            JWPLAYER:'HofBP5zj.js'    
        },
    stage: { BASE_URL: "https://tapi.hedgehogconcept.net/wexer",
            RESET_PASSWORD: "https://ma.activenation.org.uk/Account/ForgotPassword",
            JWPLAYER:'wUkkXJdm.js'    
        },
    development: { BASE_URL: "https://tapi.hedgehogconcept.net/wexer",
                RESET_PASSWORD: "https://ma.activenation.org.uk/Account/ForgotPassword",
                JWPLAYER:'HofBP5zj.js'     
        },
}

function getEnvironment() {
    const PLATFORM = process.env.REACT_APP_PLATFORM;

    let platform  //getPlatform();
    if (PLATFORM === "FF-INT") {
      platform = "development";
    } else if (PLATFORM === "TEST") {
      platform = "test";
    } else if (PLATFORM === "STAGE") {
      platform = "stage";
    } else if (PLATFORM === "PROD") {
      platform = "production";
    } 
  
    return _Environments[platform]  
}

var Environment = getEnvironment()  
module.exports = Environment 
