
import { create } from 'apisauce';
//import { encode as btoa } from "base-64";
import Environment from "./config";


const baseURL = Environment.BASE_URL;


const generateApiEnvironment = () => {
      const  API = create({
            baseURL: baseURL,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
             }
        });
    return API;
}

 

 

export default generateApiEnvironment;









 

